import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const AFKIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page afk-journey '} game="afk">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - AFK Journey wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for AFK Journey. Check our guides, tier lists and
          reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      {/* <SectionHeader title="Easyfun - Play AFK Journey on Browser without downloading!" />
      <div className="banner-ldp-promo">
        <h4>Play AFJ Journey on Browser</h4>
        <h5>Without downloading - just click to play!</h5>
        <OutboundLink
          href="https://www.easyfun.gg/games/afk-journey-cloud-online.html?from=prydwen"
          target="_blank"
        >
          <Button variant="primary">
            Play now <FontAwesomeIcon icon={faPlay} width="18" />
          </Button>
        </OutboundLink>
      </div> */}
      <SectionHeader title="Active codes" />
      <p>Check all active codes here:</p>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Promo codes"
          link="/afk-journey/codes"
          image={
            <StaticImage
              src="../../images/afk/categories/category_codes.jpg"
              alt="Promo codes"
            />
          }
        />
      </Row>
      <SectionHeader title="Primal Lord" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Primal - Crazed Shellbrute"
          link="/afk-journey/guides/primal-shellbrute"
          image={
            <StaticImage
              src="../../images/afk/categories/category_brute.webp"
              alt="Primal Lord - Crazed Shellbrute"
            />
          }
        />
        <CategoryCard
          title="Primal - Plague Creeper"
          link="/afk-journey/guides/primal-creeper"
          image={
            <StaticImage
              src="../../images/afk/categories/category_creep.webp"
              alt="Primal Lord - Plague Creeper"
            />
          }
        />
      </Row>
      <SectionHeader title="Dream Realm guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Crystal Beetle"
          link="/afk-journey/guides/dream-realm-crystal-beetle"
          image={
            <StaticImage
              src="../../images/afk/categories/category_crystal.webp"
              alt="Crystal Beetle"
            />
          }
          updated
        />
        <CategoryCard
          title="Orson"
          link="/afk-journey/guides/dream-realm-orson"
          image={
            <StaticImage
              src="../../images/afk/categories/category_orson.webp"
              alt="Orson"
            />
          }
          updated
        />
        <CategoryCard
          title="Lone Gaze"
          link="/afk-journey/guides/dream-realm-lone-gaze"
          image={
            <StaticImage
              src="../../images/afk/categories/category_lone.jpg"
              alt="Primal Lord - Lone Gaze"
            />
          }
          updated
        />
        <CategoryCard
          title="Alpha Bear"
          link="/afk-journey/guides/dream-realm-alpha-bear"
          image={
            <StaticImage
              src="../../images/afk/categories/category_bear.jpg"
              alt="Primal Lord - Alpha Bear"
            />
          }
          updated
        />
        <CategoryCard
          title="King Croaker"
          link="/afk-journey/guides/dream-realm-croaker"
          image={
            <StaticImage
              src="../../images/afk/categories/category_croaker.jpg"
              alt="King Croaker"
            />
          }
          updated
        />
        <CategoryCard
          title="Necrodrakon"
          link="/afk-journey/guides/dream-realm-necrodrakon"
          image={
            <StaticImage
              src="../../images/afk/categories/category_nekro.jpg"
              alt="Necrodrakon"
            />
          }
          updated
        />
        <CategoryCard
          title="Skyclops"
          link="/afk-journey/guides/dream-realm-skyclops"
          image={
            <StaticImage
              src="../../images/afk/categories/category_skyclops.jpg"
              alt="Skyclops"
            />
          }
          updated
        />
        <CategoryCard
          title="Snow Stomper"
          link="/afk-journey/guides/dream-realm-snow-stomper"
          image={
            <StaticImage
              src="../../images/afk/categories/category_yeti.jpg"
              alt="Snow Stomper"
            />
          }
          updated
        />
      </Row>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Frequently Asked Questions"
          link="/afk-journey/guides/frequently-asked-questions"
          image={
            <StaticImage
              src="../../images/afk/categories/category_faq.jpg"
              alt="Frequently Asked Questions"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/afk-journey/guides/reroll"
          image={
            <StaticImage
              src="../../images/afk/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/afk-journey/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_beginner.jpg"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Tips and Tricks"
          link="/afk-journey/guides/tips-and-tricks"
          image={
            <StaticImage
              src="../../images/afk/categories/category_tips.jpg"
              alt="Tips and Tricks"
            />
          }
        />
        <CategoryCard
          title="Wishlist tips"
          link="/afk-journey/guides/wishlist-tips"
          image={
            <StaticImage
              src="../../images/afk/categories/category_wishlist.jpg"
              alt="Wishlist tips"
            />
          }
          updated
        />
        <CategoryCard
          title="Characters list"
          link="/afk-journey/characters"
          image={
            <StaticImage
              src="../../images/afk/categories/category_characters.jpg"
              alt="Characters list"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/afk-journey/tier-list"
          image={
            <StaticImage
              src="../../images/afk/categories/category_tier.jpg"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Team building (beginner)"
          link="/afk-journey/guides/team-building"
          image={
            <StaticImage
              src="../../images/afk/categories/category_team.jpg"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Team building (advanced)"
          link="/afk-journey/guides/team-building-advanced"
          image={
            <StaticImage
              src="../../images/afk/categories/category_meta.jpg"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Exclusive Weapon priority"
          link="/afk-journey/guides/exclusive-weapon-priority"
          image={
            <StaticImage
              src="../../images/afk/categories/category_ex.jpg"
              alt="Exclusive Weapon priority"
            />
          }
        />
        <CategoryCard
          title="Shop (Emporium) guide"
          link="/afk-journey/guides/shop-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_shops.jpg"
              alt="Shop (Emporium) guide"
            />
          }
        />
        <CategoryCard
          title="Spending guide"
          link="/afk-journey/guides/spending-guide"
          image={
            <StaticImage
              src="../../images/afk/categories/category_spending.jpg"
              alt="Spending guide"
            />
          }
        />
        <CategoryCard
          title="Relentless Rumble (2)"
          link="/afk-journey/guides/relentless-rumble"
          image={
            <StaticImage
              src="../../images/afk/categories/category_rumble.webp"
              alt="Relentless Rumblee"
            />
          }
          updated
        />
        <CategoryCard
          title="Guild - Glyphshade boss"
          link="/afk-journey/guides/guild-glyphshade"
          image={
            <StaticImage
              src="../../images/afk/categories/category_glyph.webp"
              alt="Guild - Glyphshade boss"
            />
          }
        />
        {/* <CategoryCard
          title="Battle Drills (Season)"
          link="https://www.afkanalytica.com/journey/battle-drill/song-of-strife"
          image={
            <StaticImage
              src="../../images/afk/categories/category_battles.jpg"
              alt="Guilds - Battle Drills"
            />
          }
          outsideLink
        /> */}
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AFKIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="AFK Journey Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for AFK Journey. Check our guides, tier lists and reviews for characters available in the game."
    game="afk"
  />
);
